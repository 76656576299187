import styled, { css } from "styled-components";
import { colors } from './../../variables';

export const ShowWrapper = styled.div`
  width: 1000px;
  background-color: transparent;
  border-radius: 0px;
  position: relative;
  margin-top: 50px;
  color: #000;
  z-index: 30;
  left: 5%;
  text-align: center;
  .myVideo-dimensions.vjs-fluid{
    padding-top: 55vh;
    @media (max-height: 700px){
      padding-top: 40vh;
    }
  }
  
  



  p{
    color: #fff;
  }
  button{
    justify-content: center;
    display: inline-block;
    @media (max-width: 1600px){
      background-color: #000;
      color: #fff;
    }
  }
  @media (max-width: 1600px){
    width: 600px;
  }
  @media (max-width: 991px){
    width: 500px;
    margin-left: -250px;
    left: inherit;
    
  }
  @media (max-width: 991px){
    position: relative;
    margin: 0 auto;
    background-color: transparent;
    margin-top: 80px;
  }
  @media (max-width: 767px){
    width: 95%;
    left: 50%;
    margin-left: -47.5%;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 100%;
  margin: 25px 0px;
  background-color: #aaa;
  opacity: 0.3;
`;

export const ShowContent = styled.div`
  padding: 0px;
  padding-bottom: 30px;
  color: #fff;
  text-align: center;
  @media (max-width: 767px){
    padding: 30px 20px;
    color: #fff;
  }
`;

export const ShowTitle = styled.div`
  margin-bottom: 20px;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 32px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  @media (max-width: 991px){
    color: #000;
    text-align: center;
  }
`;

export const ShowMessage = styled.div`

  margin-top: 20px;
  color: #fff;
  text-transform: normal;
  font-size: 16px;
  p{
    color: #000;
  }
`;

export const Video = styled.div`
  background-color: #000;
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  height: auto!important;
  margin: 0 auto;
  margin-top: 0px;
  border: 1px solid #aaa;
  @media (min-width: 992px){
    
  }
  @media (max-width: 767px){
    width: 100%;
    height: auto;
  }
`;

export const ShareTitle = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
`;

export const ShareList = styled.ul`
  margin: 0px;
  padding: 0px;
  margin-top: 15px;
`;

export const ShareListItem = styled.li<{color: string}>`
  list-style: none;
  display: inline-block;
  margin: 5px 5px;
  color: #fff;
  
  a{
    background-color: ${props => props.color};
    color: #fff;
    display: flex;
    font-size: 11px;
    padding: 5px 10px;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
  }
  img{
    height: 15px;
    width: auto;
    margin-right: 5px;
  }
`;