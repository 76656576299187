import * as React from 'react';
import Layout from '../layout';
import { TitleRecord, SubtitleMobile, ButtonMobile, RecordWrapper, ListModules, WebcamWrapper, RecordHeader, StepNumber, ActionsWrapper, ModuleListItem, ModuleContainer, OnDesktop, OnMobile} from './styled';
import { Link, useHistory } from 'react-router-dom';

import icoStart from './images/ico-circle.svg';
import icoStop from './images/ico-stop.svg';
import icoPause from './images/ico-pause.svg';
import icoPlay from './images/ico-play.svg';
import icoRetry from './images/ico-retry.svg';
import icoValidate from './images/ico-check.svg';

import { Button } from '../../widgets/globaleStyledWidget';
import { colors, lang } from '../../variables';


import 'video.js/dist/video-js.css';
import videojs from 'video.js';

import 'webrtc-adapter';
import RecordRTC from 'recordrtc';

import 'videojs-record/dist/css/videojs.record.css';
import 'videojs-record/dist/videojs.record.js';
import livstickApp from '../../models/livstick';

import {FormattedMessage, injectIntl, useIntl} from 'react-intl';


const videoJsOptions = {
  controls: true,
  bigPlayButton: false,
  fluid: true,
  plugins: {
    /*
    // wavesurfer section is only needed when recording audio-only
    wavesurfer: {
      backend: 'WebAudio',
      waveColor: '#36393b',
      progressColor: 'black',
      debug: true,
      cursorWidth: 1,
      msDisplayMax: 20,
      hideScrollbar: true,
      displayMilliseconds: true,
      plugins: [
        // enable microphone plugin
        WaveSurfer.microphone.create({
          bufferSize: 4096,
          numberOfInputChannels: 1,
          numberOfOutputChannels: 1,
          constraints: {
              video: false,
              audio: true
          }
        })
      ]
    },
    */
    record: {
      audio: window.innerWidth > 991,
      video: true,
      maxLength: 120,
      debug: true,
      videoMimeType: 'video/webm;codecs=vp9'
    }
  }
};

const RecordPage:React.FunctionComponent = () => {

  const [moduleIndex, setModuleIndex] = React.useState<number>(0);
  const [playerState, setPlayerState] = React.useState<number>(0);
  const [player, setPlayer] = React.useState<any>(null);

  const history = useHistory();
  
  const openModule = (index: number) => {
    setModuleIndex(index);
  }

  const videoEl = React.useRef(null);
  const fileEl = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    console.log('ref', videoEl);

    fileEl.current.addEventListener('change', (e) => {
      livstickApp.videoFile = fileEl.current.files[0];
      history.push('message');
    })
    
    if (window.innerWidth > 640) var _player = videojs(videoEl.current, videoJsOptions, () => {
      const version_info = 'Using video.js ' + videojs.VERSION +
                ' with videojs-record ' + videojs.getPluginVersion('record') +
                ' and recordrtc ' + RecordRTC.version;
            videojs.log(version_info);
            console.log(player);
            _player.record().getDevice();
            setPlayer(_player)

            

            _player.on('finishRecord', function() {
              // the blob object contains the recorded data that
              // can be downloaded by the user, stored on server etc.
              console.log('finished recording:', _player.recordedData);

              livstickApp.videoFile = _player.recordedData;
          });
    });
  }, [])

  const startRecord = () => {
    
    player.record().start();
    setPlayerState(1);
  }

  const uploadFile = () => {
    fileEl.current.click();
    
  }

  const resumeRecord = () => {
    setPlayerState(1)
    player.record().resume();
  }

  const pauseRecord = () => {
    setPlayerState(2)
    player.record().pause();
  }

  const endRecord = () => {
    setPlayerState(3)
    player.record().stop();
    // console.log('FINISHED recording: ', player.recordedData)
    // livstickApp.videoFile = player.recordedData;
    // console.log('FINISHED recording: ', livstickApp.videoFile)
  }

  const readRecord = () => {
    setPlayerState(3)
    player.play();
  }

  const validate = () => {

    history.push('message')
  }

  const intl = useIntl();

  return <Layout>
    <RecordWrapper>
      <RecordHeader>
        <TitleRecord dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "global_txt_title"})}}></TitleRecord>
        <StepNumber><FormattedMessage id="global_txt_step"/> 1/2</StepNumber>
      </RecordHeader>
      <ModuleContainer>
        <input type="file" style={{display: 'none'}} ref={fileEl} accept="video/*"/>
        {moduleIndex == 0 && <div>
          <OnDesktop>
          <WebcamWrapper>
            <video id="myVideo" ref={videoEl} className="video-js vjs-default-skin" playsInline></video>
          </WebcamWrapper>
          
          <ActionsWrapper>
            {[1].includes(playerState) && <Button color={colors.secondary} hover="secondary" onClick={pauseRecord}><FormattedMessage id="player_txt_button_pause"/></Button>}
            {[2].includes(playerState) && <Button color={colors.secondary} hover="secondary" onClick={resumeRecord}><FormattedMessage id="player_txt_button_resume"/></Button>}
            {[3].includes(playerState) && <Button color={colors.secondary} hover="secondary" onClick={startRecord}><FormattedMessage id="player_txt_button_retry"/></Button>}
            {[1, 2].includes(playerState) && <Button color={colors.secondary} hover="secondary" onClick={endRecord}> <FormattedMessage id="player_txt_button_stop"/></Button>}
            {[3].includes(playerState) && <Button color={colors.secondary} hover="secondary" onClick={readRecord}><FormattedMessage id="player_txt_button_play"/></Button>}
            {/*ROMAIN CHANGEMENT BUTTON*/}{[0].includes(playerState) && <Button className="button-mobile" color={colors.secondary} hover="secondary" onClick={startRecord}><FormattedMessage id="player_txt_button_start"/></Button>}
            {/*ROMAIN CHANGEMENT BUTTON*/}{[0].includes(playerState) && <Button className="button-mobile" color={colors.secondary} hover="secondary" onClick={uploadFile}><FormattedMessage id="player_txt_button_import"/></Button>}
            {/*ROMAIN CHANGEMENT BUTTON*/}{[3].includes(playerState) && <Button className="button-mobile" color={colors.secondary} hover="secondary" onClick={validate}> <FormattedMessage id="player_txt_button_send"/></Button>}
          </ActionsWrapper>
          </OnDesktop>
          <OnMobile>
            
            <SubtitleMobile><FormattedMessage id="player_txt_title" /></SubtitleMobile>
            {[0].includes(playerState) && <ButtonMobile className="button-mobile button-special" onClick={uploadFile}><FormattedMessage id="player_txt_video_button" /></ButtonMobile>}
          </OnMobile> 
        </div>}
      </ModuleContainer>
      
    </RecordWrapper>
    
  </Layout>
}

export default injectIntl(RecordPage);