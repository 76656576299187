import * as React from 'react';
import { MainWrapper, VideoBackground, VideoBackgroundMobile, PictureBackground } from './styled';
import videoMp4 from './images/vm-final.mp4';
import videoWebm from './images/vm-final.webm';
import videoMp4Mobile from './images/mobile.mp4';
import videoWebmMobile from './images/mobile.webm';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";


const Main:React.FunctionComponent = ({children}) => {
    return <MainWrapper>
        <BrowserView>
        <PictureBackground></PictureBackground>
        </BrowserView>
        {children}
    </MainWrapper>;
}

export default Main;
